<template>
  <div>
    <div class="text-center mt-6 mb-6" v-if="!shippingAddresses.length">
      {{ $t('noAddresses') }}
    </div>
    <template v-else>
      <NeoBoxCheck
        editable
        v-for="item in shippingAddresses"
        :key="item.id"
        :default-label="'Default Address'"
        @select="selectAddress(item)"
        @editAddress="editAddress(item)"
        @deleteAddress="deleteAddress(item)"
        @defaultAddress="setDefaultAddress(item)"
        :value="item"
        :selected="selectedAddressId"
      >
        <div class="font-weight-bold">{{ item.name }}</div>
        <div class="font-weight-light">
          <i>{{ item.line1 }}</i>
        </div>
        <div class="font-weight-light">
          <i>{{ item.line2 }}</i>
        </div>
        <div>
          {{ item.postcode }} | {{ item.city }} | {{ item.region }} |
          {{ item.country }}
        </div>
      </NeoBoxCheck>
    </template>

    <AddressForm
      v-if="modalForm"
      :info="addressInfo"
      @close="closeForm"
      @clickOutsideCallback="closeForm"
      @input="submitAddress"
    />
    <div class="d-flex align-center justify-center">
      <v-btn color="primary" @click="openForm">
        {{ $t('addNew') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import AddressForm from '@/components/Organisms/AddressForm';
import NeoBoxCheck from '@/components/Molecules/NeoBoxCheck';
import UsersDS from '@/services/UsersDS';

export default {
  name: 'UserAddress',
  components: {
    AddressForm,
    NeoBoxCheck,
  },
  data: () => {
    return {
      updateAddress: false,
      addressInfo: {},
      selectedAddress: {},
      selectedAddressId: '',
      shippingAddresses: [],
      useForShipping: true,
      userInfo: {},
      modalForm: false,
    };
  },
  methods: {
    submitAddress(modalInfo) {
      // Update NOA user info
      if (!this.updateAddress) {
        const newAddress = UsersDS.addShippingAddress(
          this.$store.state.user.uid,
          modalInfo
        );
        newAddress.then((docRef) => {
          modalInfo.id = docRef.id;
          if (this.shippingAddresses.length === 0) {
            // First address added
            this.setDefaultAddress(modalInfo);
          }
          // this.shippingAddresses.push(modalInfo);
          // this.selectedAddress = modalInfo;
        });
      } else {
        UsersDS.updateShippingAddress(this.$store.state.user.uid, modalInfo);
      }
      this.getShippingAddresses();

      // Update Stripe user address

      this.closeForm();
    },
    openForm() {
      this.modalForm = true;
    },
    closeForm() {
      this.modalForm = false;
      this.updateAddress = false;
      this.addressInfo = {};
    },
    deleteAddress(address) {
      if (address.defaultAddress) {
        // Automatically set a new default address
        if (this.shippingAddresses.length === 1) {
          // No other address, unset default address in the database
          this.setDefaultAddress('');
        } else {
          this.shippingAddresses.some((other) => {
            if (other.id !== address.id) this.setDefaultAddress(other.id);
            return;
          });
        }
      }
      UsersDS.deleteShippingAddress(this.$store.state.user.uid, address.id);
      this.getShippingAddresses();
    },
    editAddress(addressInfo) {
      event.stopPropagation();
      this.addressInfo = addressInfo;
      this.updateAddress = true;
      this.modalForm = true;
    },
    setDefaultAddress(address) {
      UsersDS.update(this.$store.state.user.uid, {
        default_shipping_address: address.id,
      });
      // this.selectedAddress = this.shippingAddresses.filter((address) => {
      //   return address.id === addressId;
      // });
      this.getShippingAddresses();
      this.selectAddress(address);
    },
    selectAddress(address) {
      this.selectedAddressId = address.id;
      this.selectedAddress = address;
      // console.info(address);
      this.$emit('setAddress', address);
    },
    getShippingAddresses() {
      UsersDS.getDefaultShippingAddress(this.$store.state.user.uid).then(
        (snap) => {
          const shippingAddresses = UsersDS.getShippingAddresses(
            this.$store.state.user.uid
          );
          shippingAddresses.then((data) => {
            this.shippingAddresses = [];
            // console.log(snap.data()['default_shipping_address'], data);
            data.docs.forEach((address) => {
              let addressInfo = address.data();
              addressInfo.id = address.id;
              addressInfo.defaultAddress =
                address.id === snap.data()['default_shipping_address'];
              if (address.id === snap.data()['default_shipping_address']) {
                this.selectedAddressId = address.id;
                this.selectedAddress = addressInfo;
                this.selectAddress(addressInfo);
              }

              this.shippingAddresses.push(addressInfo);
            });
          });
        }
      );
    },
    selectShippingAddress() {},
  },
  mounted() {
    this.getShippingAddresses();
  },
};
</script>
