<template>
  <NeoModal
    :max-width="800"
    :title-header="$t('editInfo')"
    scrollable
    @close="closeForm"
    @clickOutsideCallback="closeForm"
  >
    <v-card-text>
      <div class="profile-modal-form">
        <div class="neo-text-field">
          <div>{{ $t('fullName') }}</div>
          <v-text-field
            ref="firstName"
            v-model="address.name"
            :rules="[rules.required]"
            :error-messages="errorMessages"
            :label="$t('fullName')"
            required
          ></v-text-field>
        </div>

        <div class="neo-text-field">
          <div>{{ $t('phoneNumber') }}</div>
          <v-text-field
            ref="telephone"
            v-model="address.telephone"
            :rules="[rules.required, rules.number]"
            :label="$t('phoneNumber')"
            required
          ></v-text-field>
        </div>

        <div class="neo-text-field">
          <div>{{ $t('addressLine1') }}</div>
          <v-text-field
            ref="line1"
            v-model="address.line1"
            :rules="[rules.required, rules.max]"
            :label="$t('addressLine1')"
            counter="25"
            required
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t('addressLine2') }}</div>
          <v-text-field
            ref="line2"
            v-model="address.line2"
            :rules="[rules.required, rules.max]"
            :label="$t('addressLine2')"
            counter="25"
            required
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t('city') }}</div>
          <v-text-field
            ref="city"
            v-model="address.city"
            :rules="[rules.required]"
            :label="$t('city')"
            required
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t('region') }}</div>
          <v-text-field
            ref="region"
            v-model="address.region"
            :rules="[rules.required]"
            :label="$t('region')"
            required
          ></v-text-field>
        </div>
        <div class="neo-text-field">
          <div>{{ $t('postcode') }}</div>
          <v-text-field
            ref="postcode"
            v-model="address.postcode"
            :rules="[rules.required]"
            :label="$t('postcode')"
            required
          ></v-text-field>
        </div>

        <div class="neo-text-field">
          <div>{{ $t('country') }}</div>
          <v-autocomplete
            ref="country"
            v-model="address.country"
            :rules="[rules.required]"
            :items="countries"
            :label="$t('country')"
            placeholder="Select..."
            required
          ></v-autocomplete>
        </div>
        <v-checkbox
          v-model="address.useForBilling"
          :label="$t('sameAddressBilling')"
        >
        </v-checkbox>
      </div>
    </v-card-text>
    <template slot="actions">
      <v-btn @click="closeForm" class="mr-6" outlined color="primary">{{
        $t('cancel')
      }}</v-btn>

      <v-slide-x-reverse-transition>
        <v-tooltip v-if="formHasErrors" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="my-0"
              v-bind="attrs"
              @click="resetForm"
              v-on="on"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh form</span>
        </v-tooltip>
      </v-slide-x-reverse-transition>

      <v-btn color="primary" @click="submit">{{ $t('submit') }}</v-btn>
    </template>
  </NeoModal>
</template>

<script>
import NeoModal from '@/components/Molecules/NeoModal';
export default {
  name: 'AddressForm',
  components: {
    NeoModal,
  },
  props: {
    info: Object,
  },
  data: () => ({
    countries: ['Japan'],
    address: {
      name: '',
      line1: '',
      line2: '',
      city: '',
      region: '',
      postcode: '',
      telephone: '',
      country: '',
      useForBilling: true,
    },
    errorMessages: '',
    formHasErrors: false,
    rules: {
      required: (value) => !!value || 'Required.',
      max: (v) => (v && v.length <= 25) || 'Max 25 characters',
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || 'E-mail must be valid',
      number: (v) => Number.isInteger(Number(v)) || 'Invalid telephone number',
    },
  }),
  methods: {
    closeForm() {
      this.address = {
        name: '',
        line1: '',
        line2: '',
        city: '',
        region: '',
        postcode: '',
        telephone: '',
        country: '',
        useForBilling: true,
      };
      this.$emit('close');
    },
    submit() {
      this.$emit('input', this.address);
    },
  },
  mounted() {
    if ('name' in this.info) this.address = this.info;
  },
  unmounted() {
    this.address = {};
  },
};
</script>

<style lang="scss" scoped>
.profile-modal-form {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  row-gap: 8px;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.profile-modal-form-item-block {
  grid-column: 1 / -1;
}
</style>
